<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList()"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.name"
            placeholder="公司名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      stripe
      ref="dataList"
      @select-all="handleSelectAll"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司名称"
      >
      </el-table-column>
      <el-table-column
        prop="recentChangedTime"
        header-align="center"
        align="center"
        label="账户最近一次变动时间"
      >
      </el-table-column>
      <el-table-column
        prop="companyTotalRecharge"
        header-align="center"
        align="center"
        label="总充值金额"
      >
        <template slot-scope="scope">
          <el-tag effect="plain" size="small"
            >￥{{ scope.row.companyTotalRecharge }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="allocatedMoney"
        header-align="center"
        align="center"
        label="已分配金额"
      >
        <template slot-scope="scope">
          <el-tag type="danger" effect="plain" size="small"
            >￥{{ scope.row.allocatedMoney }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="balance"
        header-align="center"
        align="center"
        label="剩余金额"
      >
        <template slot-scope="scope">
          <el-tag type="success" effect="plain" size="small"
            >￥{{ scope.row.balance }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="creditMoney"
        header-align="center"
        align="center"
        label="赊账额度"
      >
        <template slot-scope="scope">
          <el-tag type="info" effect="plain" size="small"
            >￥{{ scope.row.creditMoney }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="thisMonthEmployeesSpent"
        header-align="center"
        align="center"
        label="员工本月消费金额"
      >
        <template slot-scope="scope">
          <el-tag type="warning" effect="plain"
            >￥{{ scope.row.thisMonthEmployeesSpent }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="220"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('tc:companyaccountmain:update')"
            type="text"
            size="mini"
            @click="$dialog('addOrUpdate', scope.row.id)"
            >修改赊账额度</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <!-- 弹窗，修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList"
    />
  </div>
</template>

<script>
import AddOrUpdate from './companyaccountmain-add-or-update';
import { listMixin, normal } from '@/mixins';

export default {
  mixins: [listMixin, normal],
  data() {
    return {
      searchForm: {
        name: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },

  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/tc/companyaccountmain/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          name: this.searchForm.name,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
  },
};
</script>
